@use "../../theme";

.pipeline-card {
  &.card {
    height: 100%;
    border-radius: 10px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
    background: theme.$primary;
    border: none;
    .card-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.7rem 1rem;

      border: 1px solid rgba(0, 0, 0, 0.08);
      border-bottom: none;
      border-radius: 0.8rem 0.8rem 0 0 !important;

      .card-title {
        color: white;
        margin-bottom: 0;
      }
    }
    .card-body {
      background-color: #fff;
      border-radius: 0 0 0.5rem 0.5rem !important;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-top: none;

      p {
        text-align: justify;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
