.add-samples {
  .gr-add {
    svg {
      path {
        stroke: white;
      }
    }
  }
  .text-danger {
    path {
      stroke: #dc3545;
    }
  }
  .simple-table {
    .table {
      tbody {
        td.filename {
          word-break: break-all;
        }
        td.lane {
          .input-group {
            border-radius: 50px;
            flex-wrap: nowrap;

            input {
              width: 60px;
            }
          }
        }
        td.platform {
          width: 150px;
        }
      }
    }
  }
}
