.reset-filters-btn {
  svg {
    path {
      stroke: #e67225;
    }
  }
  &:hover,
  &:active {
    svg {
      path {
        stroke: white;
      }
    }
  }
}
