@use "./theme";

/* import bootstrap to set changes */
@use "~bootstrap/scss/bootstrap" with (
  $primary: theme.$primary,
  $dark: theme.$dark,
  $secondary: theme.$secondary,
  $success: theme.$success,
  $light: theme.$light,
  $input-focus-border-color: theme.$secondary,
  $input-focus-box-shadow: none,
  $input-border-width: 2px,
  $form-select-focus-box-shadow: none
);

/* Bootstrap component overrides */
.btn,
.badge,
:not(textarea).form-control,
.progress,
.input-group-text {
  border-radius: 50px;
}

.input-group-sm .input-group-text {
  border-radius: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.input-group-sm .form-control {
  border-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.modal-content {
  border-radius: 10px;
}

.badge {
  padding: 0.5em 0.8em;
}

.progress {
  height: 1.5rem;
}

.btn-primary,
.btn-secondary {
  color: white !important;
}

.modal-1000px {
  max-width: 1000px;
}

.flex-item-same-width {
  flex: 1 1 0px;
}

.description-tooltip {
  .tooltip-inner {
    max-width: 600px;
  }
}

.btn-outline-secondary {
  &:active,
  &.active,
  &:focus-visible,
  &:hover {
    color: white !important;
  }
}

.btn:focus,
.btn:active {
  box-shadow: none !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.table {
  th.file-status,
  td.file-status {
    width: 0.1%;
    padding: 0rem;
    span {
      margin: 0.5rem 1rem;

      svg {
        position: relative;
        top: -2px;
      }
    }
  }
}
