.full-width-chart-container {
  height: 600px;
  width: 100% !important;
  margin: 0 0;
}

.plotly-wrapper {
  height: 750px;
  width: 100% !important;
  margin: 0 0;
  display: flex;
  justify-content: center;
}
