@use "../../../theme";

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: theme.$grey-light;
  border-radius: 10px;
  opacity: 0.7;
  z-index: 10;

  &.top-spinner {
    align-items: flex-start;
    padding-top: 10%;
  }
  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
  &.fixed {
    position: fixed;
  }
}
