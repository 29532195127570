.supporting-files {
  padding-top: 0.7rem;

  .text-danger {
    path {
      stroke: #dc3545;
    }
  }

  & > div {
    padding-top: 0.75rem;
  }
  h5 {
    font-weight: 500;
    display: flex;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
  }

  .supporting-file-btn {
    width: 70px;
  }
}
