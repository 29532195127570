.genedata {
  .get-results {
    min-width: 150px;
  }

  .chart-wrapper {
    padding: 20px;
  }

  .simple-table {
    .table {
      th {
        white-space: pre;
        padding-left: 50px;
        padding-right: 50px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      td {
        padding-left: 50px;
        padding-right: 50px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 40px;
  }
}

.genedata-hitlists {
  max-width: 70% !important;

  table {
    margin-bottom: 20px;
    tbody {
      tr {
        cursor: pointer;
        td {
          white-space: nowrap;

          &.ID {
            padding-right: 30px !important;
          }
        }
      }
    }
  }
}
