.add-genedata-modal {
  .breadcrumbs {
    min-height: 24px;
  }
  .table {
    thead {
      th.added {
        width: 1%;
        padding: 0.5rem;
      }
    }

    tbody {
      td.added {
        text-align: center;
        padding: 0.5rem;
      }
    }
  }
}
