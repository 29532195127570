.experiments {
  td.status,
  td.updated,
  td.pipeline {
    white-space: nowrap;
  }

  td.progressbar {
    padding-top: 12px !important;
  }

  .reset-filters-btn {
    &:hover {
      svg {
        path {
          stroke: white;
        }
      }
    }
    svg {
      position: relative;
      top: 0.06em;
      path {
        stroke: #e67225;
      }
    }
  }
}
