.upload-by-url-modal {
  .table {
    tbody,
    thead,
    th,
    tr,
    td {
      border: 0;
    }
  }
  textarea {
    border-radius: 25px;
  }
}

.samples {
  .text-warning {
    svg {
      path {
        stroke: #ffc107;
      }
    }
  }

  .add-folder-btn {
    svg {
      stroke-width: 0.3;
    }
  }
}

.table {
  th.is_archived {
    width: 0.1%;
  }
  td.is_archived {
    text-align: center;
    span {
      margin: 0.5rem 1rem;

      svg {
        position: relative;
        top: -2px;
      }
    }
  }
}
