@use "../../theme";

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 260px;
  background: transparent;
  height: 70px;
  color: white;
  z-index: 10;
  padding: 15px;

  &.scrolled {
    @include theme.bg-gradient();
  }

  &.sidebar-collapsed {
    left: 70px;
  }

  .toggle-sidebar {
    height: 70px;
    width: 70px;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    font-size: 40px;
    cursor: pointer;

    &:hover i {
      font-size: 1.05em;
    }
  }

  .actions {
    .btn:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
