.card-container {
  .card {
    border-radius: 10px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);

    .card-subtitle {
      display: flex;
      padding-left: 15px;
      font-size: 1rem;
      line-height: 1.5;
      justify-content: space-between;
      width: 100%;
    }

    .card-header {
      background: transparent;
      border: none;
      border-radius: 0.8rem 0.8rem 0 0;
      padding: 0.7rem 1rem 0 1rem;

      .card-title {
        display: flex;
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.5;
        justify-content: space-between;
        width: 100%;
      }

      .card-actions {
        display: flex;
        margin-left: 1rem;
        svg {
          width: 1.3rem;
          height: 1.3rem;
        }
      }

      .spinner-container {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .spinner-border {
          width: 1rem;
          height: 1rem;
          border-width: 2px;
        }
      }
    }

    .card-body {
      padding: 0.75rem 1rem 1rem 1rem;
    }
  }
}

.subtitle {
  padding: 10px;
}
