.experiment {
  .preview-btn {
    svg {
      position: relative;
      top: -1px;
    }
  }
}
.supporting-files {
  .simple-table {
    .table {
      tbody {
        td.name {
          word-break: break-all;
        }
      }
    }
  }
}
