@use "../../theme";

.logout-backdrop {
  background-color: theme.$grey-light;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  justify-content: center;

  .logout-container {
    border-radius: 10px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
    width: 450px;
    height: 250px;

    .card-body {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;

      img {
        width: 80%;
      }
    }
  }
}
