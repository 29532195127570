.error-modal {
  .modal-content {
    border: none;
  }
  .modal-header {
    padding: 1rem 1.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .modal-title {
      font-size: 30px;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      line-height: 0;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}
