@use "../../../../theme";

.pipeline-overview {
  .card {
    background-color: theme.$primary-light;

    .flex-container {
      & > div {
        flex: 1 1 0px;
      }
    }

    .overview {
      text-align: justify;
    }

    ul {
      list-style: none;
    }

    li {
      margin-top: 0.7rem;
      display: flex;
      align-items: center;

      .rounded-circle {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        background-color: theme.$primary;
        color: white;
        margin-right: 1rem;
        line-height: 1 !important;
        width: 24px;
        height: 24px;
        position: relative;
        top: 1px;
      }
    }
  }
}
