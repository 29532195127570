@use "../../theme";

.pipeline-info-modal {
  max-width: 1000px !important;

  .modal-body {
    & > * > div {
      display: flex;
      justify-content: space-between;

      & > div {
        flex: 1 1 0px;
        text-align: justify;
      }

      & > :not(:first-child) {
        margin-left: 2rem;
      }

      h4 {
        margin-bottom: 1.5rem;
      }

      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          span {
            color: white;
            display: flex !important;
            justify-content: center !important;
            width: 40px !important;
            height: 40px !important;
            line-height: 1 !important;
            background-color: theme.$primary;
            border-radius: 50% !important;
            padding: 0.7rem 1rem;
            margin-bottom: auto;
            margin-right: 1rem;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
