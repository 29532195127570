.sequence-browser {
  .export-to-csv-btn,
  .filter-with-csv-btn {
    min-width: 130px;
  }
  .table-filters {
    .btn {
      min-width: 130px;
    }
  }
}

.mass-sequence-check-modal {
  .sequences-container {
    position: relative;
  }
}
