.add-input-files-modal {
  .breadcrumbs {
    min-height: 24px;
  }
  .table {
    thead {
      th.added {
        width: 1%;
        padding: 0.5rem;
      }
    }

    tbody {
      td.added {
        text-align: center;
        padding: 0.5rem;
      }

      .text-warning {
        svg {
          path {
            stroke: #ffc107;
          }
        }
      }
      td.filename {
        svg {
          position: relative;
          top: -2px;
        }
      }
    }
  }
}
