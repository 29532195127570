@use "../../../theme";

.icon-btn-container {
  display: inline-block;
  margin: 0 0.5rem;

  &[disabled] {
    cursor: not-allowed;
  }

  &.bold {
    .bi::before {
      font-weight: bold !important;
    }
  }

  .icon-btn {
    color: #09163a;

    &.active,
    &:hover {
      color: theme.$primary;
    }

    &[disabled] {
      pointer-events: none;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      position: relative;
      top: -3px;
      outline: none;
      &:hover path {
        stroke: theme.$primary;
      }
      path {
        stroke: #09163a;
      }
    }
  }

  .badge {
    position: absolute;
    border-radius: 0.25rem;
    font-size: 9px;
    right: -10px;
    top: -2px;
    padding: 0.35em 0.65em;
  }
}
