.removable-badge {
  svg {
    position: relative;
    top: -1px;
    margin-left: 5px;
    cursor: pointer;
    path {
      stroke: White;
      stroke-width: 3;
    }
  }
}
