@use "../../../theme";

.common-table {
  .table {
    margin-top: 0.5rem;
    margin-bottom: 0;
    & > :not(:first-child) {
      border-top: none;
    }

    & > :not(caption) > * > * {
      padding: 0.5rem 2rem;

      &:first-child {
        padding: 0.5rem 2rem 0.5rem 0.5rem;
      }

      &:last-child.actions {
        padding: 0.5rem;
      }
    }

    &.table-sm {
      & > :not(caption) > * > * {
        padding: 0.25rem;
        &:first-child {
          padding: 0.25rem;
        }
        &:last-child.actions {
          padding: 0.25rem;
        }
        .badge {
          font-size: 0.7em;
          margin: 0.2em 1em;
          padding: 0.35em 0.65em;
        }
      }

      th.sortable {
        &::before {
          top: 3px;
        }
        &::after {
          bottom: 3px;
        }
      }
    }

    thead {
      th {
        border-bottom: 0;
        white-space: nowrap;
      }
      th.actions,
      th.row-expand {
        width: 1%;
        text-align: center;
      }

      th.sortable {
        padding-right: 30px;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          display: inline-block;
          left: auto;
          right: 0.5rem;
          opacity: 0.3;
          font-size: 0.7rem;

          font-style: normal;
          font-weight: normal !important;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
        }
        &::before {
          content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='currentColor' viewBox='0 0 14 14'><path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/></svg>");
          top: 7px;
        }

        &::after {
          content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='currentColor' viewBox='0 0 14 14'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
          bottom: 7px;
        }

        &.asc::before {
          opacity: 1;
        }
        &.desc::after {
          opacity: 1;
        }
      }
    }

    tbody {
      tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }

      tr.clickable {
        cursor: pointer;
      }

      td.nohover {
        --bs-table-accent-bg: none !important;
      }
    }
    td.actions,
    td.row-expand {
      width: 1%;
      text-align: center;
    }
    td.actions {
      white-space: nowrap;

      button:last-child {
        margin-right: 0 !important;
      }
    }

    td.selection {
      width: 30px;
    }

    td.updated,
    td.last_modified,
    td.size {
      white-space: nowrap;
    }

    th.selection,
    td.selection,
    th.row-expand,
    td.row-expand {
      padding-right: 0;
    }

    td {
      vertical-align: middle;
    }

    td.sub-table-filler {
      padding: 0;
      width: 1%;
    }
  }

  .form-check {
    padding-top: 0.125rem;
  }

  label {
    margin-bottom: 0;
  }

  .table-info {
    background-color: transparent;
  }

  .table-pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-rows {
      label {
        display: inline-block;
      }
      select {
        display: inline-block;
        width: auto;
        border: none;
      }
    }
    .table-pagination {
      display: flex;
      .icon-btn-container {
        &.disabled {
          opacity: 0.4;
          .icon-btn {
            &:hover {
              color: #98a6ad;
            }
            cursor: default;
          }
        }
        .icon-btn {
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .pagination-container {
      justify-content: space-around;
    }
  }
}
