@use "../../../theme";

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .folder-icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    position: relative;
    top: -1px;
  }
  .divider {
    margin: 0 1rem;
  }

  a {
    color: #09163a;

    &.active,
    &:hover {
      color: theme.$primary;
    }

    text-decoration: none;
  }
}
