@use "../../theme";

.dropdown-container {
  .user-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: theme.$primary;
    color: white !important;
    text-transform: capitalize;
    font-weight: bold;
    font-family: monospace;
    font-size: 25px;
    text-decoration: none;
    border: none !important;
    box-shadow: none !important;

    &:disabled {
      opacity: 1;
    }
  }
  .dropdown-menu {
    padding: 0.2rem 0;
    min-width: auto;
  }
}
