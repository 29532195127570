.simple-table {
  .table {
    margin-bottom: 0;

    &.table > :not(:first-child) {
      border-top: none;
    }

    thead {
      th {
        border-bottom: 0;
        white-space: nowrap;

        &.actions {
          width: 1%;
          text-align: center;
        }
      }
    }

    tbody {
      td {
        vertical-align: middle;
        &.actions {
          width: 1%;
          text-align: center;
          white-space: nowrap;
        }
      }
      tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}
