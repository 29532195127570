.sample-browser {
  .more-filters {
    position: relative;
    top: 15px;
  }

  .rw-widget-picker {
    min-height: 40px;
  }

  .table {
    td.sample_id,
    td.experiment\.completed,
    td.seq_format {
      white-space: nowrap;
    }
    td.actions svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .more-filters-btn {
    svg {
      polyline {
        stroke: #278aca;
      }
    }
    &:hover {
      svg {
        polyline {
          stroke: #1f6ea2;
        }
      }
    }
  }
  .visualize-btn {
    svg {
      path {
        stroke: #e67225;
      }
    }
    &:hover,
    &:active {
      svg {
        path {
          stroke: white;
        }
      }
    }
  }
  .reset-selection-btn {
    svg {
      position: relative;
      top: 1px;
      path {
        stroke: #1f6ea2;
      }
    }
    &:hover,
    &:active {
      svg {
        path {
          stroke: #1f6ea2;
        }
      }
    }
  }
}

.invalid_charts {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
