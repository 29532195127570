.setup {
  .advanced-settings {
    text-decoration: none;
    svg {
      polyline {
        stroke: #278aca;
      }
    }
  }

  .number-input {
    width: 75px;
  }
}
