@use "../../theme";

.sidebar-wrapper {
  width: 260px;
  z-index: 11;
  position: fixed;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  .sidebar-toggle {
    position: relative;
    .sidebar-toggle-btn {
      background-color: theme.$primary;
      border: 0px;
      border-radius: 50%;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px,
        rgb(0 0 0 / 10%) 0px 2px 4px 1px;
      color: white;
      cursor: pointer;
      height: 24px;
      width: 24px;
      outline: 0px;
      padding: 0px;
      position: absolute;
      top: 63px;
      right: -12px;

      .sidebar-toggle-hitbox {
        position: absolute;
        inset: -8px -12px -8px -8px;
      }

      svg {
        pointer-events: none;
        top: -2px;
        position: relative;

        polyline {
          stroke: white;
          stroke-width: 3;
        }
      }
    }
  }

  &.sidebar-collapsed {
    width: 70px;

    .logo-container {
      margin: 0.7rem 0rem;
      margin-bottom: 0;
      .logo-small {
        display: block;
      }

      .logo {
        display: none;
      }
    }

    .sidebar-main {
      .divider {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }

      .side-nav-title {
        display: none;
      }
      .side-nav {
        margin-left: 0;
        .side-nav-link {
          padding: 15px 0;
          font-size: 24px;
          text-align: center;
          justify-content: center;

          &.active,
          &:hover {
            svg {
              path {
                stroke: theme.$primary;
              }
            }
          }

          svg {
            margin-right: 0;
          }
        }
      }
    }
  }

  .logo-container {
    margin: 0.8rem 1.5rem;
    margin-bottom: 0;
    .logo-small {
      display: none;
    }
  }

  .sidebar-main {
    margin: 0.5rem;
    margin-top: 0;
    height: 100%;

    .divider {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .side-nav {
      padding-left: 0;
      list-style-type: none;
      margin-left: 1rem;

      .side-nav-link {
        display: block;
        padding: 10px 0px 10px 10px;
        position: relative;
        list-style: none;
        text-decoration: none;
        font-size: 19px;
        font-weight: 500;

        &.active,
        &:hover {
          color: theme.$primary !important;
          svg {
            path {
              stroke: theme.$primary;
            }
          }
        }

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .side-nav-title {
      letter-spacing: 0.05em;
      pointer-events: none;
      cursor: default;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #cedce4;
      font-weight: 700;
    }
  }
}
