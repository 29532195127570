$dark: #09163a;
$primary: #278aca;
$secondary: #e67225;
$success: #5eb546;
$light: #fff;
$primary-light: #c6e2f4;
$grey-light: #f7f7f7;

@mixin bg-gradient() {
  background-image: linear-gradient(50deg, #5eb546 50%, #278aca 100%);
}
