@use "../../theme";

#wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .content-wrapper {
    overflow: hidden;
    margin-left: 260px;
    padding: 70px 1.5rem 55px;
    min-height: 100vh;
    position: relative;
    background-color: theme.$grey-light;
    border: solid rgba(0, 0, 0, 0.15);
    border-width: 1px 0;
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
      inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);

    &.sidebar-collapsed {
      margin-left: 70px;
    }
  }

  #content {
    &::before {
      background-color: transparent;
      background-image: url(../../media/omniab_header.jpg);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;

      content: " ";
      height: 264px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    #content-inner {
      position: relative;
    }
  }

  .content-wrapper {
    .loader.fixed {
      margin-left: 260px;
    }
    &.sidebar-collapsed {
      .loader.fixed {
        margin-left: 70px;
      }
    }
  }
}
